import axios from 'axios';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getAllJobs = (body = { pageNumber: 1 }) => {
    return axios.post(`${ baseApiUrl }/jobs/list`, {
        page: body.pageNumber,
        limit: 10,
        filter: body.filter
    });
}
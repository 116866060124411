import React from 'react';

import { NavLink } from "react-router-dom";
// import Slider from 'react-slick';

import './Footer.component.scss';

// import IMAGE1 from '../../assets/images/footer1.png';
// import IMAGE2 from '../../assets/images/footer2.png';
// import IMAGE3 from '../../assets/images/footer3.png';
// import IMAGE4 from '../../assets/images/footer4.png';

// import FOOTER_LOGOS from '../../assets/images/footer-logos.svg';


const Footer = () => {
    // const [ currentSlide, setCurrentSlide ] = useState(0);
    // const images = [IMAGE1, IMAGE2, IMAGE3, IMAGE4, IMAGE1, IMAGE2, IMAGE3, IMAGE4, IMAGE1, IMAGE2, IMAGE3, IMAGE4];
    // const setting = {
    //     infinite: true,
    //     autoplay: true,
    //     speed: 500,
    //     slidesToShow: 8,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     beforeChange: (current,next)=> setCurrentSlide(next),
    //     responsive: [
    //         {
    //             breakpoint: 340,
    //             settings: {
    //                 slidesToShow: 1,
    //               },
    //         },
    //         {
    //             breakpoint: 576,
    //             settings: {
    //                 slidesToShow: 2,
    //               },
    //         },
    //         {
    //             breakpoint: 680,
    //             settings: {
    //                 slidesToShow: 3,
    //               },
    //         },
    //         {
    //             breakpoint: 992,
    //             settings: {
    //                 slidesToShow: 4,
    //               },
    //         },
    //         {
    //             breakpoint: 1280,
    //             settings: {
    //                 slidesToShow: 5,
    //               },
    //         },
    //         {
    //             breakpoint: 1440,
    //             settings: {
    //                 slidesToShow: 7,
    //               },
    //         },
            
    //     ]
    // };



    return (
        <footer className="footer-wrapper">
            
            {/* <div className="brand-partners-wrapper"></div> */}

            <div className="navigation-wrapper">
                <NavLink 
                    end
                    to="/"
                    className={({ isActive }) => isActive ? 'nav-link nav-link_active' : 'nav-link' }
                >
                    HOME
                </NavLink>
                <div className='vertical-divider'></div>
                <NavLink 
                    to="/about"
                    className={({ isActive }) => isActive ? 'nav-link nav-link_active' : 'nav-link' }
                >
                    ABOUT
                </NavLink>
                <div className='vertical-divider'></div>
                <NavLink 
                    to="/jobs"
                    className={({ isActive }) => isActive ? 'nav-link nav-link_active' : 'nav-link' }
                >
                    JOBS
                </NavLink>
                {/* <div className='vertical-divider'></div>
                <NavLink 
                    to="/watch"
                    className={({ isActive }) => isActive ? 'nav-link nav-link_active' : 'nav-link' }
                >
                    WATCH
                </NavLink> */}
                <div className='vertical-divider'></div>
                {/* <NavLink 
                    to="/join-us"
                    className={({ isActive }) => isActive ? 'nav-link nav-link_active' : 'nav-link' }
                >
                    JOIN US
                </NavLink> */}
                <a 
                    href="https://www.adidas.co.uk/football"
                    className='nav-link'
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    ADIDAS FOOTBALL
                </a>
                <div className='vertical-divider'></div>
                <a 
                    href="https://merkyfchq.com"
                    className='nav-link'
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    MERKYFC HQ
                </a>
            </div>

            <div className="footer-pattern"></div>

            {/* <div className="carousel-container">
                <Slider { ...setting }>
                    {
                        images.map((image, index)=> (
                            <div key={index} className={index === currentSlide ? "slide active" : 'slide'} tabIndex="-1">
                                <img src={image} alt={image} />
                            </div>
                        ))
                    }
                </Slider>
            </div> */}

            <div className="footer-links-wrapper">
                <NavLink 
                    to="/terms-and-conditions"
                    className={({ isActive }) => isActive ? 'footer-link footer-link_active' : 'footer-link' }
                >
                    Terms & Conditions
                </NavLink>
                <NavLink 
                    to="/privacy"
                    className={({ isActive }) => isActive ? 'footer-link footer-link_active' : 'footer-link' }
                >
                    Privacy
                </NavLink>
            </div>

        </footer>
    )
}

export default Footer;
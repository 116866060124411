import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';

import './Header.component.scss';

// import APP_LOGO_LARGE from '../../assets/images/3D_LOGO_LARGE.gif';
import APP_LOGO_SMALL from '../../assets/images/3D_LOGO_SMALLER.gif';

function Header(props) {
    const [menuToggler, setMenuToggler] = useState(false);
    const { pathname } = useLocation();

    const hamburgerState = props.hamburgerState;
    useEffect(() => {
        if(menuToggler) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [menuToggler]);

    useEffect(() => {
        window.addEventListener("scroll", (event) => {
            if(window.scrollY > 600) {
                document.getElementById("brand-wrapper").style.scale="0.92";
            } else {
                document.getElementById("brand-wrapper").style.scale="1";
            }
        });
    }, []);

    const onToggleMenu = (e) => {
        e.preventDefault();

        if (!hamburgerState.isShowHamburger) {
            hamburgerState.setIsShowHamburger(true);
            return;
        }
        setMenuToggler(prevState => {
            // if(window.innerWidth > 992) {
            //     return false;
            // }
            return prevState = !prevState;
        });
    }
    const onCollapseMenu = () => {
        setMenuToggler(prevState => false);
        window.scrollTo(0, 0);
    }
    const onBrandClick = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    
    return (
        <div className="header-wrapper">
            {
                pathname !== '/'
                    ? (
                        <NavLink
                            className="brand-wrapper"
                            id="brand-wrapper"
                            to="/"
                            onClick={ onBrandClick }
                        >
                            <img src={ APP_LOGO_SMALL } className="brand" alt="MERKYFC" />
                        </NavLink>
                    )
                    : (
                        <Link
                            to="homeHero"
                            spy={true}
                            smooth={true}
                            className="brand-wrapper"
                            id="brand-wrapper"
                        >
                            <img src={ APP_LOGO_SMALL } className="brand" alt="MERKYFC" />
                        </Link>
                    )
            }

            <div className="navigation-wrapper">
                <a
                    href='/#'
                    className={ menuToggler ? 'navigation-toggler expanded' : 'navigation-toggler collapsed' }
                    onClick={ onToggleMenu }
                >
                    <div className={!hamburgerState.isShowHamburger ? 'close-icon' : ''}></div>
                </a>
                <div className={ menuToggler ? 'navigation-container' : 'navigation-container collapsed' }>
                    <div className="nav-links-wrapper">
                        <NavLink
                            onClick={ onCollapseMenu }
                            className={ ({isActive}) => isActive ? 'nav-link nav-link_active' : 'nav-link' }
                            to="/"
                            end
                        >
                            home
                        </NavLink>
                        <NavLink
                            onClick={ onCollapseMenu }
                            className={ ({isActive}) => isActive ? 'nav-link nav-link_active' : 'nav-link' }
                            to="/about"
                        >
                            about
                        </NavLink>
                        <NavLink
                            onClick={ onCollapseMenu }
                            className={ ({isActive}) => isActive ? 'nav-link nav-link_active' : 'nav-link' }
                            to="/jobs"
                        >
                            jobs
                        </NavLink>
                        {/* <NavLink
                            onClick={ onCollapseMenu }
                            className={ ({isActive}) => isActive ? 'nav-link nav-link_active' : 'nav-link' }
                            to="/watch"
                        >
                            watch
                        </NavLink> */}
                        {/* <NavLink 
                            onClick={ onCollapseMenu }
                            className={ ({isActive}) => isActive ? 'nav-link nav-link_active' : 'nav-link' }
                            to="/join-us"
                        >
                            join us
                        </NavLink> */}
                        <a
                            href="https://www.adidas.co.uk/football"
                            className='nav-link'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ADIDAS FOOTBALL
                        </a>
                        <a
                            href="https://merkyfchq.com"
                            className='nav-link'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            merkyfc hq
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
import React, { useState, useEffect } from 'react';

import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';

import { animateScroll as scroll } from 'react-scroll';
import { getContent } from '../../services/content.service';

import './Home.component.scss';

// import HOME_BACKGROUND from '../../assets/videos/home-background.mp4';
// import HOME_BACKGROUND_MOBILE from '../../assets/videos/home-background-mobile.mp4';

function Home() {

    useEffect(() => {
        onHomeClick();
        // document.addEventListener('mousedown', ()=>{
        //     document.getElementById("home-video").muted = false;
        //     document.getElementById("home-video").play();
        // });
        // document.addEventListener('keydown', ()=>{
        //     document.getElementById("home-video").muted = false;
        //     document.getElementById("home-video").play();
        // });
    }, []);

    useEffect(()=>{
        getContent('landing')
        .then((res) => {
            console.log(res.data);
            document.getElementById("homeContent").innerHTML = res.data.data.content;
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    const [isMovable, setIsMovable] = useState(false);

    const handleScroll = () => {
        const scrollPercentage = (window.scrollY * 100) / window.innerHeight;
        if(scrollPercentage > 30) {
            setIsMovable(prevState => !prevState);
            document.getElementById('scrollButton').click();
        }
    }

    useEffect(() => {
        const scrollPercentage = (window.scrollY * 100) / window.innerHeight;
        if(scrollPercentage < 30) {
            window.addEventListener('scroll', handleScroll);
        } else if(isMovable) {
            window.removeEventListener('scroll', handleScroll);
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [isMovable]);

    const onHomeClick = () => {
        scroll.scrollToTop({
            duration: 500,
        });
    }

    const getBackground = () => {
        const screenSize = window.innerWidth;
        if(screenSize < 768) {
            return "https://merkyfc.s3.eu-west-2.amazonaws.com/home-background.mov";
        } else {
            return "https://merkyfc.s3.eu-west-2.amazonaws.com/home-background.mov";
        }
    }

    const getPromotionVideo = () => {
        const screenSize = window.innerWidth;
        if(screenSize < 768) {
            return 'https://merkyfc.s3.eu-west-2.amazonaws.com/landing/TMC_907_MERKY+LAUNCH+_MOBILE.mp4';
        } else if(screenSize < 1280) {
            return 'https://merkyfc.s3.eu-west-2.amazonaws.com/landing/TMC_907_MERKY+LAUNCH+_TABLET.mp4';
        } else {
            return 'https://merkyfc.s3.eu-west-2.amazonaws.com/landing/TMC_907_MERKY+LAUNCH+_DESKTOP.mp4';
        }
    }

    return (
        <div className="home-wrapper">

            <div className="hero-section" id="homeHero">
                <video className="hero-video" muted autoPlay loop id="home-video">
                    <source src={ getBackground() } type="video/mp4" />
                </video>
                {/* <div className="landing-page-logo"></div> */}
                <a target="_blank" href="https://wearediverse.teamtailor.com/en-GB/jobs/5214882-merky-fc-programme-application/applications/new?secret=2ef1d080-7d22-4a33-a6bc-a0dd47979348" className="apply-wrapper"></a>
            </div>
            

            <div className="home-content">
                <div className="content-wrapper" id="homeContent">
                    <h4 className="title">
                        Merky FC
                    </h4>
                    <p className="content">
                    Welcome to #Merky FC, a partnership between adidas and Stormzy. <br></br>

                    #Merky FC will change the game, providing young people of Black heritage with career opportunities from across the football industry<b>*</b>  <br></br>

                    Applications for opportunities nationwide are now open. <br></br><br></br>

                    
                    <b>*</b>Applicants must be residents of the UK.
                    </p>
                    <p className="content">
                        All rights reserved
                    </p>
                </div>

                <div 
                    className="image-wrapper"
                    id="homeImage"
                >
                </div>

                <div className="menu-wrapper" id="homeMenu">
                    <div className="menu-item">
                        <NavLink 
                            end
                            to="/"
                            className={({ isActive }) => isActive ? 'menu-link' : 'menu-link' }
                            onClick={ onHomeClick }
                        >home</NavLink>
                    </div>
                    <div className="menu-item">
                        <NavLink 
                            to="/about"
                            className={({ isActive }) => isActive ? 'menu-link' : 'menu-link' }
                        >about</NavLink>
                    </div>
                    <div className="menu-item">
                        <NavLink 
                            to="/jobs"
                            className={({ isActive }) => isActive ? 'menu-link' : 'menu-link' }
                        >jobs</NavLink>
                    </div>
                    {/* <div className="menu-item">
                        <NavLink 
                            to="/watch"
                            className={({ isActive }) => isActive ? 'menu-link' : 'menu-link'}
                        >watch</NavLink>
                    </div> */}
                    {/* <div className="menu-item">
                        <NavLink 
                            to="/join-us"
                            className={({ isActive }) => isActive ? 'menu-link' : 'menu-link'}
                        >join us</NavLink>
                    </div> */}
                    <div className="menu-item">
                        <a 
                            href="https://www.adidas.co.uk/football"
                            className='menu-link'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ADIDAS FOOTBALL
                        </a>
                    </div>
                    <div className="menu-item">
                        <a 
                            href="https://merkyfchq.com"
                            className='menu-link'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            MERKYFC HQ
                        </a>
                    </div>
                </div>

            </div>

            {/* <div className="video-section">
                <video className="video" controls>
                    <source 
                        src={ getPromotionVideo() }
                        type="video/mp4" 
                    />
                </video>
            </div> */}

            <Link to="homeImage" 
                spy={true}
                smooth={true}
                duration={500}
                id="scrollButton"
                className="scroll-button" 
            ></Link>
        </div>
    )
}

export default Home;
import React, { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Header from './components/header/Header.component';
import Home from './pages/home/Home.component';
import About from './pages/about/About.component';
import Jobs from './pages/jobs/Jobs.component';
// import Watch from './pages/watch/Watch.component';
// import SinglePageWatch from './pages/single-page-watch/SinglePageWatch.component';
// import SignUp from './pages/sign-up/SignUp.component';
import Footer from './components/footer/Footer.component';
// import Apply from './pages/apply/Apply.component';
// import MailingList from './pages/mailing-list/MailingList.component';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy.component';
import Terms from './pages/terms/Terms.component';


import './App.scss';

function App() {

  const [isShowHamburger, setIsShowHamburger] = useState(true);

  return (
    <div className="app-wrapper" >
      <Header hamburgerState={{ isShowHamburger, setIsShowHamburger }} />
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/about" element={ <About /> } />
        <Route path="/jobs" element={ <Jobs hamburgerState={{ isShowHamburger, setIsShowHamburger }} /> } />
        {/* <Route path="/sign-up" element={ <SignUp /> } /> */}
        {/* <Route path="/watch" element={ <Watch /> } /> */}
        {/* <Route path="/watch" element={ <SinglePageWatch /> } /> */}
        {/* <Route path="/apply" element={ <Apply /> } /> */}
        {/* <Route path="/join-us" element={ <MailingList /> } /> */}
        <Route path="/privacy" element={ <PrivacyPolicy /> } />
        <Route path="/terms-and-conditions" element={ <Terms /> } />
        <Route path="*" element={ <Navigate to="/" /> } />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;

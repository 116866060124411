import React, { useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { getContent } from '../../services/content.service';

import './Terms.component.scss';

function Terms() {


    useEffect(()=>{
        getContent('terms-condition')
        .then((res) => {
            console.log(res.data);
            document.getElementById("terms-content").innerHTML = res.data.data.content;
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    useEffect(() => {
        scroll.scrollToTop({
            duration: 500,
        });
    }, []);

    return (
        <div className="terms-wrapper">
            <div className="title-wrapper">
                <h1 className="title">
                    Terms & Conditions
                </h1>
            </div>

            <div className="content-wrapper" id="terms-content">
            </div>

        </div>
    )
}

export default Terms;
import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { animateScroll as scroll } from 'react-scroll';
import { getContent } from '../../services/content.service';

import './About.component.scss';

import ABOUT_VIDEO from '../../assets/images/about-video.jpg';

function About() {

    useEffect(() => {
        scroll.scrollToTop({
            duration: 500,
        });
    }, []);

    useEffect(()=>{
        getContent('about')
        .then((res) => {
            console.log(res.data);
            document.getElementById("aboutContent").innerHTML = res.data.data.content;
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <div className="about-wrapper">
            <div className="title-wrapper">
                <h1 className="title">
                    About
                </h1>
            </div>

            <div className="video-wrapper">
                <img className="video" src={ ABOUT_VIDEO } alt="ABOUT_VIDEO" />
            </div>

            <div className="content-wrapper">
                <div className="content">
                    <div className="content-container details-wrapper" id="aboutContent">
                    </div>
                    <p className="link-wrapper">
                        Click 
                        <Link to="/jobs" className="link">
                            HERE 
                        </Link>
                        to go to our jobs board
                    </p>
                </div>
            </div>

        </div>
    )
}      

export default About;